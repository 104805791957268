import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { DotButton } from "./EmblaCarouselButtons";
import "../css/embla.css";

const EmblaCarouselComponent = ({ children, enableDots = false, enableButtons = true, style = {}, loop = true }) => {
  const [emblaRef, embla] = useEmblaCarousel({
    loop: loop,
    align: "start",
    startIndex: 0,
  });
  const [, setPrevBtnEnabled] = useState(false);
  const [, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback((index) => embla.scrollTo(index), [embla]);
  // const scrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
  // const scrollNext = useCallback(() => embla.scrollNext(), [embla]);

  useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(embla.selectedScrollSnap());
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    };
    if (embla) {
      setScrollSnaps(embla.scrollSnapList());
      embla.on("select", onSelect);
      onSelect();
    }
  }, [embla]);

  return (
    <div className="embla embla__viewport" ref={emblaRef}>
      <div className="embla__container" style={style}>
        {children.map((Child, index) => (
          <div className="embla__slide" key={`slide_${index}`}>
            {Child}
          </div>
        ))}
      </div>
      {/* {enableButtons && (
        <>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </>
      )} */}
      {enableDots && (
        <div className="embla__dots">
          {scrollSnaps.map((snap, index) => (
            <span key={`dots_${index}`}>
              <DotButton selected={index === selectedIndex} onClick={() => scrollTo(index)} />
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default EmblaCarouselComponent;
