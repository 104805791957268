import React from "react";

export const DotButton = React.memo(({ selected, onClick }) => (
  <button
    className={`embla__dot${selected ? " is-selected" : ""}`}
    onClick={onClick}
  />
));

export const PrevButton = React.memo(({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--prev hidden lg:inline-block"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg fill="none" className="embla__button__svg">
      <path
        d="M14.972 27.657l-11.93-11.93a2 2 0 0 1 0-2.828L14.972.97"
        stroke="#595a5e"
        strokeLinecap="round"
      />
    </svg>
  </button>
));

export const NextButton = React.memo(({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--next hidden lg:inline-block"
    onClick={onClick}
    disabled={!enabled}
  >
    <svg fill="none" className="embla__button__svg">
      <path
        d="M14.97.97L26.9 12.9a2 2 0 0 1 0 2.829l-11.93 11.93"
        stroke="#595a5e"
        strokeLinecap="round"
      />
    </svg>
  </button>
));
