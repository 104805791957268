import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import CourseCard from "./courseCard";
import { isCourseInCart } from "../utils/app-helpers";
import EmblaCarousel from "./EmblaCarousel";

const Carrousel = ({
  courses,
  cart,
  addToCartAction,
  loop,
  enableButtons,
  i18n,
}) => {
  useEffect(() => {
    return () => {
      return false;
    };
  }, []);

  const items = courses.map((course) => {
    const courseData = course.node;

    // comprobamos si existe el producto en el carrito para ver cual funcion de boton add/remove debe tener
    const exists = isCourseInCart(courseData, cart.products);

    //declaramos variables vacias para setearlas segun si existe o no el producto en el carro
    let funcCart = () => (e) => {
      e.preventDefault();
      e.stopPropagation();

      return undefined;
    };

    if (!exists) {
      funcCart = (product) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        addToCartAction(product);
      };
    }

    return (
      <CourseCard
        {...courseData}
        actionFunc={funcCart}
        key={`id_${courseData.ID}`}
        locale={i18n.language}
        exists={exists}
      />
    );
  });

  return <EmblaCarousel {...{ enableButtons, loop }}>{items}</EmblaCarousel>;
};

Carrousel.propTypes = {
  courses: PropTypes.array.isRequired,
  cart: PropTypes.object.isRequired,
  addToCartAction: PropTypes.func.isRequired,
};

export default withI18n()(Carrousel);
